import { useEffect, useRef, useState } from 'react'
import { useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ExpandedIcon from 'assets/icons/photo/ExpandedIcon'
import ExpansionIcon from 'assets/icons/photo/ExpansionIcon'
import { useTranslation } from 'react-i18next'
import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'
import { getSelectedFilterCount, isFilterSelected } from 'photo-module/filters-bar/core/filters.selectors'
import ShareIcon2 from 'assets/icons/navigation/ShareIcon2'
import ShareIcon3 from 'assets/icons/navigation/ShareIcon3'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: -4,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      marginLeft: -5,
      marginRight: 7,
      width: '-webkit-fill-available',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'max-content',
    margin: '4px 10px 4px 4px',
    borderRadius: 5,
    padding: '0px 10px 0px 10px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      marginRight: -8,
      width: '-webkit-fill-available',
      justifyContent: 'space-between',
    },
    justifyContent: 'space-between',

  },
  number: {
    width: 12,
    marginLeft: 8,
    marginRight: 8,
    color: theme.palette.primary.main,
  },
  divider: {
    marginTop: 12,
    marginLeft: 12,
    marginRight: 12,
    marginBottom: '0px !important',
    color: theme.palette.background.paper,
    opacity: 0.25,
    borderBottom: `1px solid ${theme.palette.background.filter}`,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 16,
      marginRight: 16,
    },
  },
  expansion: {
    marginTop: 16,
    marginLeft: 8,
  },
  collapse: {
    marginLeft: 16,
    marginRight: -16,
  },
  count: {
    color: theme.palette.background.paper,
    display: 'inline-block',
    paddingLeft: 3,
  },
  boxItem: {},
}))

const GuestsList = ({
  camera,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  const menuListRef = useRef()
  const [open, setOpen] = useState(false)
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [anchorWidth, setAnchorWidth] = useState(0)
  // eslint-disable-next-line
  useEffect(() => menuAnchor && setAnchorWidth(menuAnchor.offsetWidth))

  const openMenu = (e) => {
    setOpen(!open)
    setMenuAnchor(e.currentTarget)
    setAnchorWidth(e.currentTarget.offsetWidth)
  }

  const closeMenu = () => setMenuAnchor(null)
  const renderChildren = camera?.guests && camera?.guests?.map((value, index) => {
    const isFirstItem = index === 0
    return (
      <div ref={menuListRef} key={value}>
        { isFirstItem && <Divider className={classes.divider} /> }
        <div style={{ margin: 8 }}><ShareIcon3 style={{ marginRight: 6 }} /><span>{ value?.guestUsername }</span></div>

      </div>
    )
  })

  return (
    <Box className={classes.root}>
      <Box className={classes.box} style={{ minWidth: '99%' }} onClick={openMenu}>
        <Box display="flex" className={classes.boxItem} flexDirection="row" alignItems="center">
          <ShareIcon2 />
        </Box>

        { open
          ? <ExpandedIcon className={classes.expansion} />
          : <ExpansionIcon className={classes.expansion} /> }
      </Box>

      <Menu
        elevation={1}
        open={!!menuAnchor}
        onClose={closeMenu}
        style={{ top: -6 }}
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          disablePadding: true,
          autoFocus: false,
          autoFocusItem: false,
          style: { minWidth: anchorWidth },
        }}
      >
        { renderChildren }
      </Menu>
    </Box>
  )
}

export default GuestsList
